import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "datenschutzerklärung"
    }}>{`Datenschutzerklärung`}</h1>
    <p>{`Die folgenden Hinweise geben einen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website `}<a parentName="p" {...{
        "href": "http://www.bambus.io"
      }}>{`www.bambus.io`}</a>{` (nachfolgend die „Website“) besuchen. Die Bambus Immobilien GmbH (nachfolgend „Bambus“, „wir“, „uns“) ist Verantwortliche im Sinne der Datenschutz-Grundverordnung (Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016; „DSGVO“).`}</p>
    <p>{`Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten auf unserer Website.`}</p>
    <p>{`Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann.`}</p>
    <p>{`Verarbeitung meint jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.`}</p>
    <p>{`Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten streng vertraulich und entsprechend der gesetzlichen Daten-schutzvorschriften, vor allem der DSGVO, und dieser Datenschutzerklärung.`}</p>
    <h2 {...{
      "id": "i-informationen-über-uns-als-verantwortliche"
    }}>{`I. Informationen über uns als Verantwortliche`}</h2>
    <p>{`Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:`}</p>
    <p>{`Bambus Immobilien GmbH`}<br />{`
Luise-Ullrich-Straße 20`}<br />{`
80636 München`}<br />{`
Deutschland`}<br />{`
Telefon: +49 30 837 960 40`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:hallo@bambus.io"
      }}>{`hallo@bambus.io`}</a></p>
    <h2 {...{
      "id": "ii-datenschutzbeauftragter"
    }}>{`II. Datenschutzbeauftragter`}</h2>
    <p>{`Der Datenschutzbeauftragte der Bambus Immobilien GmbH ist:`}</p>
    <p>{`Bambus Immobilien GmbH`}<br />{`
z.H. Datenschutzbeauftragter`}<br />{`
Luise-Ullrich-Straße 20`}<br />{`
80636 München`}<br />{`
Deutschland`}<br />{`
Telefon: +49 30 837 960 40`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:hallo@bambus.io"
      }}>{`hallo@bambus.io`}</a></p>
    <h2 {...{
      "id": "iii-informationen-zur-datenverarbeitung"
    }}>{`III. Informationen zur Datenverarbeitung`}</h2>
    <h3 {...{
      "id": "1-serverdaten"
    }}>{`1. Serverdaten`}</h3>
    <p>{`Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns automatisch übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Webseite, von der aus Sie auf unsere Website gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unserer Website erfolgt, erhoben. Diese so erhobenen Daten werden vorübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.`}</p>
    <p>{`Diese Daten werden zum einen verarbeitet, um Ihnen die Nutzung unserer Website zu ermöglichen (z.B. durch Anpassung unserer Website an die Bedürfnisse Ihres Geräts).`}</p>
    <p>{`Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO, da wir die automatisch erhobenen Daten für eine effektive Bereitstellung unserer Webseite benötigen.`}</p>
    <p>{`Zum anderen verarbeiten wir diese Daten zur Optimierung unserer Website. Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.`}</p>
    <p>{`Sie haben das Recht, dieser Art der Datenverarbeitung, die auf unserem berechtigten Interesse beruht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit zu widersprechen. Um dieses Recht auszuüben, können Sie uns jederzeit unter den in Ziffer I. benannten Kontaktdaten kontaktieren.`}</p>
    <p>{`Die Daten werden spätestens nach sieben Tagen wieder gelöscht, sofern keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.`}</p>
    <h3 {...{
      "id": "2-allgemeine-informationen-über-cookies"
    }}>{`2. Allgemeine Informationen über Cookies`}</h3>
    <h4 {...{
      "id": "a-sitzungs-cookiessession-cookies"
    }}>{`a) Sitzungs-Cookies/Session-Cookies`}</h4>
    <p>{`Wir verwenden auf unserer Website sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten, Ihre IP-Adresse oder eine individuell generierte Session-ID, verarbeitet.`}</p>
    <p>{`Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen ermöglicht.`}</p>
    <p>{`Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeiten.`}</p>
    <p>{`Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unserer Website sowie dem Schutz unserer Website vor Angriffen. Die Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. f) DSGVO.`}</p>
    <p>{`Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unserer Website sowie dem Schutz unserer Website vor Angriffen. Die Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. f) DSGVO.`}</p>
    <p>{`Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.`}</p>
    <h4 {...{
      "id": "b-drittanbieter-cookies"
    }}>{`b) Drittanbieter-Cookies`}</h4>
    <p>{`Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Verbesserung der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.`}</p>
    <p>{`Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den unter Ziffer III.2.b) bereitgestellten Informationen.`}</p>
    <h4 {...{
      "id": "c-beseitigungsmöglichkeit"
    }}>{`c) Beseitigungsmöglichkeit`}</h4>
    <p>{`Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.`}</p>
    <p>{`Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.`}</p>
    <h3 {...{
      "id": "3-eingesetzte-drittanbieter-cookies"
    }}>{`3. Eingesetzte Drittanbieter-Cookies`}</h3>
    <h4 {...{
      "id": "google-analytics"
    }}>{`Google Analytics`}</h4>
    <p>{`In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (nachfolgend nur „Google Irland“) genannt.`}</p>
    <p>{`Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Häufigkeit des Besuchs unseres Internetauftritts, werden gegebenenfalls an einen Server der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“), in den USA übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog. Anonymisierungsfunktion. Durch diese Funktion kürzt Google die IP-Adresse schon innerhalb der EU bzw. des EWR.`}</p>
    <p>{`Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übermittelt werden, hat sich Google dem EU-US Privacy Shield unterworfen, `}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/EU-US-Framework"
      }}>{`https://www.privacyshield.gov/EU-US-Framework`}</a>{`.`}</p>
    <p>{`Durch die Zertifizierung nach dem EU-US Privacy Shield garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.`}</p>
    <p>{`Die so erhobenen Daten werden wiederum von Google Irland genutzt, um uns eine Auswertung über den Besuch unseres Internetauftritts sowie über die dortigen Nutzungsaktivitäten zur Verfügung zu stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die mit der Nutzung unseres Internetauftritts und der Nutzung des Internets zusammenhängen.`}</p>
    <p>{`Google Irland gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem hält Google Irland / Google unter `}<a parentName="p" {...{
        "href": "https://www.google.com/intl/de/policies/privacy/partners"
      }}>{`https://www.google.com/intl/de/policies/privacy/partners`}</a>{` weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den Möglichkeiten, die Datennutzung zu unterbinden. Zudem bietet Google Irland unter `}<a parentName="p" {...{
        "href": "https://tools.google.com/dlpage/gaoptout?hl=de"
      }}>{`https://tools.google.com/dlpage/gaoptout?hl=de`}</a>{` ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on lässt sich mit den gängigen Internet-Browsern installieren und bietet Ihnen weitergehende Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst. Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics mit, dass Informationen zum Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen. Dies verhindert aber nicht, dass Informationen an uns oder an andere Webanalysedienste übermittelt werden.`}</p>
    <p>{`Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens der Besucher unserer Website. Die Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.`}</p>
    <p>{`Sie haben das Recht, dieser Art der Datenverarbeitung, die auf unserem berechtigten Interesse beruht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit zu widersprechen. Um dieses Recht auszuüben, können Sie uns jederzeit das oben erwähnte Deaktivierungs-Add-on installieren.`}</p>
    <h4 {...{
      "id": "google-ads-mit-conversion-tracking"
    }}>{`Google Ads mit Conversion-Tracking`}</h4>
    <p>{`In unserem Internetauftritt setzen wir die Werbe-Komponente Google Ads und dabei das sog. Conversion-Tracking ein. Es handelt sich hierbei um einen Dienst von Google Irland.`}</p>
    <p>{`Falls Sie auf eine von Google Irland geschaltete Anzeige klicken, speichert das von uns eingesetzte Conversion-Tracking ein Cookie auf Ihrem Endgerät. Diese sog. Conversion-Cookies verlieren mit Ablauf von 30 Tagen ihre Gültigkeit und dienen im Übrigen nicht Ihrer persönlichen Identifikation.`}</p>
    <p>{`Sofern das Cookie noch gültig ist und Sie eine bestimmte Seite unseres Internetauftritts besuchen, können sowohl wir als auch Google Irland auswerten, dass Sie auf eine unserer bei Google Irland / Google platzierten Anzeigen geklickt haben und dass Sie anschließend auf unseren Internetauftritt weitergeleitet worden sind.`}</p>
    <p>{`Durch die so eingeholten Informationen erstellt Google Irland uns eine Statistik über den Besuch unserer Website. Zudem erhalten wir hierdurch Informationen über die Anzahl der Nutzer, die auf unsere Anzeige(n) geklickt haben sowie über die anschließend aufgerufenen Seiten unseres Internetauftritts. Weder wir noch Dritte, die ebenfalls Google Ads einsetzten, werden hierdurch allerdings in die Lage versetzt, Sie auf diesem Wege zu identifizieren.`}</p>
    <p>{`Durch die entsprechenden Einstellungen Ihres Internet-Browsers können Sie zudem die Installation der Cookies verhindern oder einschränken. Gleichzeitig können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.`}</p>
    <p>{`Ferner bietet auch Google Irland / Google unter `}<a parentName="p" {...{
        "href": "https://services.google.com/sitestats/de.html"
      }}>{`https://services.google.com/sitestats/de.html`}</a>{` `}<a parentName="p" {...{
        "href": "http://www.google.com/policies/technologies/ads/"
      }}>{`http://www.google.com/policies/technologies/ads/`}</a>{` `}<a parentName="p" {...{
        "href": "http://www.google.de/policies/privacy/"
      }}>{`http://www.google.de/policies/privacy/`}</a>{` weitergehende Informationen zu diesem Thema und dabei insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung an.`}</p>
    <p>{`Wir nutzen das Conversion-Tracking zur zielgerichteten Bewerbung unseres Angebots. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts und unserer Werbung.`}</p>
    <p>{`Sie haben das Recht, dieser Art der Datenverarbeitung, die auf unserem berechtigten Interesse beruht, jederzeit zu widersprechen. Um dieses Recht auszuüben, können Sie uns jederzeit unter den in Ziffer I. benannten Kontaktdaten kontaktieren.`}</p>
    <h4 {...{
      "id": "facebook-pixel"
    }}>{`Facebook Pixel`}</h4>
    <p>{`Mit Ihrer Einwilligung setzen wir innerhalb unseres Internetauftritts den “Besucheraktions-Pixel” der Facebook Inc., 1 Hacker Way, 94025 Menlo Park, CA 94025, USA (“Facebook”) ein.`}</p>
    <p>{`Mit der Hilfe des Facebook-Pixels können wir die Aktionen von Nutzern nachverfolgen, nachdem diese eine Facebook-Werbeanzeige gesehen oder geklickt haben. So können wir die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke erfassen. Es ist ferner möglich, die Besucher unseres Onlineangebotes als Zielgruppe („Custom Audience“) für die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interesse an den durch uns definierten Themen oder Produkten) aufweisen.`}</p>
    <p>{`Bei der Nutzung von Custom Audiences über das Pixel-Verfahren wird eine direkte Verbindung zwischen dem Browser der Nutzer und dem Server von Facebook aufgebaut. Es werden Kontextdaten (z.B. Weiterleitungs-URL, Browserinformationen und die Facebook-Nutzer-ID) sowie benutzerdefinierte Daten an Facebook übermittelt. Daneben können wir nicht ausschließen, dass weitere personenbezogene Daten der Nutzer an Facebook übermittelt und von Facebook verarbeitet werden. Dabei ist es möglich, dass Facebook den Besuch auf unserer Webseite dem entsprechenden Facebook-Account zuordnet und den Nutzer entsprechend identifiziert. Für uns sind die erfassten Daten anonym, das heißt, wir sehen nicht die personenbezogenen Daten einzelner Nutzer.`}</p>
    <p>{`Facebook kann diese Daten auch für eigene Werbezwecke entsprechend Facebooks Datenverwendungsrichtlinie verwenden. Sie können Facebook sowie dessen Partnern das Schalten von Werbeanzeigen auf und außerhalb von Facebook ermöglichen. Es kann ferner zu diesen Zwecken ein Cookie auf Ihrem Endgerät platziert werden.`}</p>
    <p>{`Die Rechtsgrundlage für den Einsatz von Facebook Custom Audiences ist Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO. Sie können Ihre Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf Ihrer Einwilligung auf Grundlage der Einwilligung erfolgten Verarbeitung wird dadurch jedoch nicht berührt.`}</p>
    <h4 {...{
      "id": "hotjar"
    }}>{`Hotjar`}</h4>
    <p>{`Wir nutzen Hotjar, um die Bedürfnisse unserer Nutzer besser zu verstehen und das Angebot und die Erfahrung auf dieser Webseite zu optimieren. Mithilfe der Technologie von Hotjar bekommen wir ein besseres Verständnis von den Erfahrungen unserer Nutzer (z.B. wieviel Zeit Nutzer auf welchen Seiten verbringen, welche Links sie anklicken, was sie mögen und was nicht etc.) und das hilft uns, unser Angebot am Feedback unserer Nutzer auszurichten. Hotjar arbeitet mit Cookies und anderen Technologien, um Daten über das Verhalten unserer Nutzer und über ihre Endgeräte zu erheben, insbesondere IP Adresse des Geräts (wird während Ihrer Website-Nutzung nur in anonymisierter Form erfasst und gespeichert), Bildschirmgröße, Gerätetyp (Unique Device Identifiers), Informationen über den verwendeten Browser, Standort (nur Land), zum Anzeigen unserer Webseite bevorzugte Sprache. Hotjar speichert diese Informationen in unserem Auftrag in einem pseudonymisierten Nutzerprofil. Hotjar ist es vertraglich verboten, die in unserem Auftrag erhobenen Daten zu verkaufen.`}</p>
    <h4 {...{
      "id": "webanalyse-mittels-matomo"
    }}>{`Webanalyse mittels Matomo`}</h4>
    <p>{`Umfang der Verarbeitung personenbezogener Daten: Wir nutzen auf dieser Website die Software „Matomo“ (`}<a parentName="p" {...{
        "href": "http://www.matomo.org"
      }}>{`www.matomo.org`}</a>{`), einem Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland. Die Software setzt ein Cookie (eine Textdatei) auf ihren Recher, mit dem Ihr Browser wiedererkannt werden kann. Werden Unterseiten unserer Webseite aufgerufen, so werden folgende Daten gespeichert:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`die IP-Adresse des Nutzers, gekürzt um die letzten zwei Bytes (anonymisiert)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`die aufgerufene Unterseite und Zeitpunkt des Aufrufs`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`die Seite, von der der Nutzer auf unsere Webseite gelangt ist (Referrer)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`welcher Browser mit welchen Plugins, welches Betriebssystem und welche Bildschirmauflösung genutzt wird`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`die Verweildauer auf der Website`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`die Seiten, die von der aufgerufenen Unterseite aus angesteuert werden`}</p>
      </li>
    </ul>
    <p>{`Die mit Matomo erhobenen Daten werden werden auf unseren eigenen Servern gespeichert. Eine Weitergabe an Dritte erfolgt nicht.`}</p>
    <p>{`Die Rechtsgrundlage, auf der wir mittels Matomo personenbezogene Daten verarbeiten, ist Art. 6 Abs. 1 lit. f der DSGVO.`}</p>
    <p>{`Wir benötigen die Daten, um das Surfverhalten der Nutzer zu analysieren und Informationen über Nutzung der einzelnen Komponenten der Website zu erhalten. Das ermöglicht uns, die Website und ihre Nutzerfreundlichkeit stetig zu optimieren. In diesen Zwecken liegt unser berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO begründet. Durch die Anonymisierung der IP-Adresse tragen wir dem Interesse der Nutzer am Schutz personenbezogener Daten Rechnung. Die Daten werden nie dazu genutzt, den Nutzer der Website persönlich zu identifizieren und werden nicht mit anderen Daten zusammengeführt.`}</p>
    <p>{`Die Daten werden gelöscht, wenn sie für unsere Zwecke nicht mehr benötigt werden.`}</p>
    <p>{`Sie können mit einem Mausklick unten ein so genanntes Opt-Out-Cookie anlegen, das zwei Jahre Gültigkeit hat. Es hat zur Folge, dass Matomo Ihre weiteren Besuche nicht registrieren wird. Beachten Sie allerdings, dass das Opt-Out-Cookie gelöscht wird, wenn Sie alle Cookies löschen.`}</p>
    <iframe style={{
      "border": "0",
      "height": "200px",
      "width": "100%",
      "marginLeft": "-8px"
    }} src="https://analytics.bambus.io/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=012A59&fontSize=18px&fontFamily=system-ui%2Csans-serif" />
    <h4 {...{
      "id": "adform"
    }}>{`Adform`}</h4>
    <p>{`Diese Website verwendet das Online-Marketing-Tool Adform von Adform A/S Dänemark. Adform nutzt das Tracking auf unserer Website über Cookies und ähnliche Tracking-Technologien auf der Grundlage von IDs (kurz: Cookie). Das Adform-Cookie wird auf unserer Website platziert, sobald Sie Ihre Zustimmung gegeben haben. Ein Adform-Cookie wird auch dann gesetzt, wenn Sie unsere über Adform auf Websites Dritter geschaltete Werbung ansehen und Sie der Setzung von Cookies auf dieser Website zugestimmt haben.`}</p>
    <p>{`Die Rechtsgrundlage für die Platzierung von Adform-Cookies ist Ihre Zustimmung. Personenbezogene Daten werden zu Analysezwecken und zur Optimierung und Personalisierung von Werbekampagnen für die Kunden von Adform, einschließlich uns, gesammelt. Der Adform-Cookie sammelt folgende Daten von uns oder unseren Werbemitteln: Cookie-ID, Gerätetyp/ID, Zeitpunkt des Anklickens der Website oder des Werbemittels, die URL der Website oder des Werbemittels, automatisch von Ihrem Gerät gesendete Informationen (einschließlich Spracheinstellung, IP-Adresse, demografische Daten), Interessendaten, soziodemografische Daten, die mit einem Cookie oder einer anderen ID verbunden sind.`}</p>
    <h3 {...{
      "id": "4-newsletter"
    }}>{`4. Newsletter`}</h3>
    <p>{`Falls Sie sich für unseren kostenlosen Newsletter anmelden, werden die von Ihnen hierzu abgefragten Daten, also Ihre E-Mail-Adresse, an uns übermittelt. Gleichzeitig speichern wir die IP-Adresse des Internetanschlusses, von dem aus Sie auf unseren Internetauftritt zugreifen, sowie Datum und Uhrzeit Ihrer Anmeldung.`}</p>
    <p>{`Für die Anmeldung zu unserem Newsletter verwenden wir das sogenannte Double-opt-in-Verfahren. Nach erfolgter Anmeldung erhalten Sie eine E-Mail an die angegebene E-Mail-Adresse, die einen Link enthält. Die Anmeldung zu unserem Newsletter ist erst abgeschlossen, wenn Sie durch Klick auf den darin enthaltenen Link bestätigt haben, dass Sie unseren Newsletter erhalten möchten. Wenn Sie die Anmeldung zu unserem Newsletter nicht innerhalb von 48 Stunden bestätigen, werden wir die Newsletteranmeldung nicht abschließen.`}</p>
    <p>{`Nach erfolgter und abgeschlossener Anmeldung verwenden wir Ihre E-Mail-Adresse für den Versand der angeforderten Informationen.`}</p>
    <p>{`Rechtsgrundlage hierbei ist die von Ihnen erteilte Einwilligung, Art. 6 Abs. 1 lit. a) DSGVO.`}</p>
    <p>{`Die Einwilligung in den Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen oder den in jedem Newsletter enthaltenen Abmeldelink betätigen. Die Rechtmäßigkeit der bereits auf Grundlage der Einwilligung erfolgten Verarbeitung bleibt vom Widerruf unberührt.`}</p>
    <h3 {...{
      "id": "5-kontaktanfragen--kontaktmöglichkeit"
    }}>{`5. Kontaktanfragen / Kontaktmöglichkeit`}</h3>
    <p>{`Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.`}</p>
    <p>{`Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.`}</p>
    <p>{`Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.`}</p>
    <h2 {...{
      "id": "iv-weitergabe-von-daten-an-dritte"
    }}>{`IV. Weitergabe von Daten an Dritte`}</h2>
    <p>{`In Übereinstimmung mit den geltenden Datenschutzbestimmungen können wir externen Auftragsverarbeitern (Art. 28 DSGVO), die in unserem Auftrag handeln und Dienste im Zusammenhang mit der Website erbringen (z.B. IT-Dienstleister), Ihre personenbezogenen Daten offenlegen. Die Datenübertragung erfolgt auf der Grundlage von Auftragsverarbeitungsverträgen. Unsere Auftragsverarbeiter dürfen Ihre personenbezogenen Daten nur insoweit verarbeiten, als dies zur Erfüllung ihrer spezifischen Aufgaben notwendig ist. Sie sind vertraglich verpflichtet, Ihre personenbezogenen Daten nur in unserem Auftrag und gemäß unseren Weisungen zu verarbeiten.`}</p>
    <p>{`Sofern diese Auftragsverarbeiter ihren Sitz außerhalb der Europäischen Union oder des Europäischen Wirtschaftsraums haben, haben sie ihren Sitz entweder in Drittstaaten, für die ein Angemessenheitsbeschluss der Europäischen Kommission vorliegt, oder ein angemessenes Schutzniveau ist gewährleistet durch von der Europäischen Kommission erlassene Standarddatenschutzklauseln, die von uns und dem externen Auftragsverarbeiter abgeschlossen wurden.`}</p>
    <h2 {...{
      "id": "v-speicherdauer"
    }}>{`V. Speicherdauer`}</h2>
    <p>{`Unser Ziel ist es, Ihre personenbezogenen Daten nur im geringstmöglichen Umfang zu ver-arbeiten. Sind in dieser Datenschutzerklärung keine genauen Speicherfristen angegeben, werden wir Ihre personenbezogenen Daten nur so lange speichern, wie sie für die Erfüllung des Zweckes, für die sie ursprünglich erhoben wurden, nötig sind und – falls zutreffend – solange wie gesetzlich vorgeschrieben.`}</p>
    <h2 {...{
      "id": "vi-ihre-rechte-nach-der-dsgvo"
    }}>{`VI. Ihre Rechte nach der DSGVO`}</h2>
    <p>{`Ihnen können insbesondere die folgenden Rechte nach der DSGVO zustehen:`}</p>
    <ul>
      <li parentName="ul">{`Recht auf Auskunft: Sie haben das Recht, jederzeit Auskunft darüber zu verlangen, ob wir personenbezogene Daten von Ihnen verarbeiten, sowie, wenn dies der Fall ist, Auskunft über diese personenbezogenen Daten zu verlangen. Um dieses Recht geltend zu machen, können Sie sich jederzeit unter den unter Ziffer I. genannten Kontaktdaten an uns wenden.`}</li>
      <li parentName="ul">{`Recht auf Berichtigung Ihrer personenbezogenen Daten: Wenn wir Ihre personenbezogenen Daten verarbeiten, bemühen wir uns, durch angemessene Maßnahmen sicherzustellen, dass Ihre personenbezogenen Daten für die Zwecke, für die sie erhoben wurden, richtig und aktuell sind. Für den Fall, dass Ihre personenbezogenen Daten unrichtig oder unvollständig sind, können Sie die Berichtigung dieser Daten verlangen. Um dieses Recht geltend zu machen, können Sie sich jederzeit unter den unter Ziffer I. genannten Kontaktdaten an uns wenden.`}</li>
      <li parentName="ul">{`Recht auf Löschung oder Einschränkung der Verarbeitung: Sie haben gegebenenfalls das Recht, die Löschung Ihrer personenbezogenen Daten oder die Einschränkung ihrer Verarbeitung zu verlangen. Um dieses Recht geltend zu machen, können Sie sich jederzeit unter den unter Ziffer I. genannten Kontaktdaten an uns wenden.`}</li>
      <li parentName="ul">{`Recht auf Datenübertragbarkeit: Sie haben gegebenenfalls das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, oder diese Daten einem anderen Verantwortlichen zu übermitteln. Um dieses Recht geltend zu machen, können Sie sich jederzeit unter den unter Ziffer I. genannten Kontaktdaten an uns wenden.`}</li>
      <li parentName="ul">{`Recht auf Widerspruch: Sie haben das Recht, der Verarbeitung Ihrer personenbezogenen Daten jederzeit zu widersprechen, sofern wir Ihre personenbezogenen Daten zu Zwecken der Direktwerbung verbarbeiten, oder sofern wir Ihre personenbezogenen Daten zur Verfolgung unserer berechtigten Interessen verarbeiten und Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben. Um dieses Recht geltend zu machen, können Sie sich jederzeit unter den unter Ziffer I. genannten Kontaktdaten an uns wenden oder Ihr Widerspruchsrecht wie in dieser Datenschutzerklärung näher beschrieben – ausüben.`}</li>
      <li parentName="ul">{`Recht auf Widerruf einer Einwilligung: Sofern unsere Datenverarbeitung auf einer Einwilligung beruht (gemäß Art. 6 Abs. 1 lit. a) DSGVO), können Sie Ihre Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf auf Grundlage der Einwilligung erfolgten Verarbeitung wird dadurch jedoch nicht berührt. Um dieses Recht geltend zu machen, können Sie sich jederzeit unter den unter Ziffer I. genannten Kontaktdaten an uns wenden.`}</li>
      <li parentName="ul">{`Recht auf Beschwerde bei einer Aufsichtsbehörde: Sie haben das Recht, sich mit einer Beschwerde an eine Aufsichtsbehörde innerhalb der Europäischen Union zu wenden.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      