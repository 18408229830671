import React from 'react'

import { Section, Container, Row, Column } from 'components'
import Layout from 'components/Layout'
import Markdown from 'components/Markdown'
import Sidebar from 'components/Sidebar'

import MarkdownContent from './privacy'

const seoTags = {
  title: 'Datenschutz - Ihre Immobilie ist sicher ',
  description:
    'Um Ihnen den besten Zugriff auf Ihr Immobilien-Vermögen zu bieten, achten wir natürlich auf Ihre Privatsphäre. Siehe dazu unsere Datenschutzbestimmungen.',
}

const Page = () => (
  <Layout seo={{ title: seoTags.title, description: seoTags.description }}>
    <Section>
      <Container>
        <Row flexWrap="wrap">
          <Column size={[1, 1, 4 / 12, null]}>
            <Sidebar title="Datenschutz" />
          </Column>
          <Column size={[1, 1, 8 / 12, null]}>
            <Markdown>
              <MarkdownContent />
            </Markdown>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
)

export default Page
