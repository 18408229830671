import React from 'react'

import { Heading, Box } from 'components'
import { PhoneLink, EmailLink } from 'components/ContactLink'
import { useLocation } from 'context/location'

const Sidebar = ({ title }) => {
  const { countryCode } = useLocation()

  return (
    <>
      <Heading.H2 as="h1">{title}</Heading.H2>
      <Box mt={4} flexWrap="wrap" display={['flex', 'null', 'block', null]}>
        <Box flexBasis={['100%', '50%', 'auto', null]}>
          <Heading.H5>Bambus Immobilien GmbH</Heading.H5>
          {countryCode === 'DE' ? (
            <>
              <p>
                Luise-Ullrich-Straße 20
                <br />
                80636 München
              </p>
              <p>
                Amtsgericht Charlottenburg
                <br />
                HRB 201351 B
              </p>
            </>
          ) : (
            <p>
              Börseplatz 4/IV
              <br />
              1010 Wien
            </p>
          )}
        </Box>

        <Box flexBasis={['100%', '50%', 'auto', null]}>
          <Heading.H5>UID</Heading.H5>
          {countryCode === 'DE' ? <p>DE322175012</p> : <p>ATU74374802</p>}
          <Heading.H5>Geschäftsführer</Heading.H5>
          <p>
            Franz Hoerhager,
            <br />
            Mag. Sebastian Pfisterer
          </p>
        </Box>

        <Box flexBasis={['100%', '50%', 'auto', null]}>
          <Heading.H5>E-Mail</Heading.H5>
          <p>
            <EmailLink />
          </p>
        </Box>

        <Box flexBasis={['100%', '50%', 'auto', null]}>
          <Heading.H5>Telefonnummer</Heading.H5>
          <p>
            <PhoneLink />
            <br />
            (9–18 Uhr)
          </p>
        </Box>
      </Box>
    </>
  )
}

export default Sidebar
