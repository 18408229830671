import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import { Heading, Text, Link } from 'components'

const Markdown = ({ children }) => {
  return (
    <MDXProvider
      components={{
        h1: (props) => (
          <Heading.H2
            as="h1"
            mt={4}
            mb={2}
            sx={{ '&:first-of-type': { mt: 0 } }}
            {...props}
          />
        ),
        h2: (props) => <Heading.H3 as="h2" mt={4} mb={2} {...props} />,
        h3: (props) => <Heading.H4 as="h3" mt={4} mb={2} {...props} />,
        h4: (props) => <Heading.H5 as="h4" mt={4} mb={2} {...props} />,
        h5: (props) => <Heading.H6 as="h5" mt={4} mb={2} {...props} />,
        h6: (props) => <Heading.H6 as="h6" mt={4} mb={2} {...props} />,
        p: (props) => <Text mb={2} as="p" {...props} />,
        a: (props) => <Link {...props} />,
      }}
    >
      {children}
    </MDXProvider>
  )
}

export default Markdown
